<template>
  <div class="row">
    <div class="col-md-12">
      <div
        style="
          background-color: #b00202;
          border-radius: 5px;
          padding: 15px 15px 15px 15px;
          border-left: 8px solid #7a0101;
          color: whitesmoke;
        "
      >
        <h3 style="border-bottom: 2px solid #7a0101; margin-bottom: 10px">
          Catatan
        </h3>

        <ul style="margin-left: -20px">
          <li>
            Halaman ini digunakan untuk memilih ujian (tryout) online yang
            disediakan oleh bimbel sejawat. Ujian gratis akan muncul tanpa user
            harus melakukan registrasi pada periode.
          </li>
          <li>
            Jika user sudah melakukan registrasi pada periode yang disediakan,
            dan apabila pada periode tersebut diadakan ujian maka user akan
            mendapatkan akses pada ujian tersebut.
          </li>
          <li>
            Tiap peserta ujian hanya diperbolehkan untuk mengikuti masing-masing
            ujian yang tersedia hanya 1 KALI SAJA.
          </li>
          <li>
            Dan apabila terjadi kegagalan koneksi dan peserta terpaksa tidak
            bisa melanjutkan ujian. Maka, telah disediakan fitur untuk mengulang
            try out dengan cara menghubungi admin Sejawat LC untuk dibukakan
            kembali.
          </li>
        </ul>
      </div>
    </div>

    <div class="col-md-12 mt-3 text-center" v-if="openExamsList.length <= 0">
      <base-alert type="success">
        <span>Sorry! There is no open exam for you right now.</span>
      </base-alert>
    </div>

    <div class="col-md-12" v-else>
      <md-card
        v-for="openExam in openExamsList"
        :key="openExam.id"
        class="md-danger sm mt-3"
        md-with-hover
        style="
          border-radius: 3px;
          -moz-user-select: none;
          -khtml-user-select: none;
          -webkit-user-select: none;
          -ms-user-select: none;
          user-select: none;
        "
      >
        <md-card-header>
          <div class="md-title">
            Open Exam
            <span v-if="openExam.free_exam == 1"> - FREE! </span>
          </div>
          <div class="md-subhead">
            This exam is only valid from
            {{ formatDate(openExam.start_date) }}
            until
            {{ formatDate(openExam.end_date) }}
          </div>
        </md-card-header>

        <md-card-content>
          <div v-if="openExam.free_exam == 0">
            Hai para sejawat. Ada try out yang terbuka untukmu nih. Akses pada
            try out ini terbuka karena kamu sudah join periode tutor berbayar
            Sejawat LC.
            <md-divider class="mt-2 mb-2" />
            Spesifikasi try out ini adalah sebagai berikut:
            <ul style="margin-left: -20px">
              <li>
                Jumlah Soal:
                <b style="color: #e1a500">{{ openExam.total_questions }}</b>
                butir
              </li>
              <li>
                Durasi Pengerjaan:
                <b style="color: #e1a500">{{ openExam.duration }}</b> menit
              </li>
            </ul>
          </div>
          <div v-else>
            Hai para sejawat. Sejawat LC sedang membuka try out gratis nih.
            Buruan dicoba! (limited time offer)
            <md-divider class="mt-2 mb-2" />
            Spesifikasi try out ini adalah sebagai berikut:
            <ul style="margin-left: -20px">
              <li>
                Jumlah Soal:
                <b style="color: #e1a500">{{ openExam.total_questions }}</b>
                butir
              </li>
              <li>
                Durasi Pengerjaan:
                <b style="color: #e1a500">{{ openExam.duration }}</b> menit
              </li>
            </ul>
          </div>
        </md-card-content>

        <md-card-actions>
          <md-button @click="takeOnExam(openExam.id)">
            I Am Ready! Take Me To The Exam
          </md-button>
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>

<script>
import BaseAlert from "../../components/BaseAlert.vue";
export default {
  name: "tryouts-list",
  components: { BaseAlert },
  data() {
    return {
      openExamsList: [],
    };
  },
  created() {
    window.aku = this;

    // load open tryouts from API
    this.loadOpenTryouts();
  },
  methods: {
    loadOpenTryouts() {
      this.apiGet("getOpenTryouts/" + this.$store.state.userData.userId)
        .then((response) => {
          this.openExamsList = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    takeOnExam(examId) {
      // taking on exam requires user confirmation
      // show confirmation message
      this.$swal({
        title: "Are You Sure?",
        text:
          "You can only take the exam ONCE. Make sure you have STABLE CONNECTION.",
        icon: "question",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          // user confirmed the action
          // get user id from store
          let userId = this.$store.state.userData.userId;

          // check if user is allowed to take this exam
          this.apiGet("checkUserExamValidity/" + userId + "/" + examId)
            .then((response) => {
              // check API response if user is permitted to take the exam
              if (!response.data.allowed) {
                this.showApiErrorMessage(
                  "Exam already taken, you cannot take the exam again."
                );
              } else {
                // post data to API to generate user's exam questions
                this.apiPost("takeExam", {
                  user_id: userId,
                  exam_id: examId,
                })
                  .then((response) => {
                    // check response code for success status detection
                    if (response.status == 200) {
                      this.$router.push(
                        "/tryout-paper/" +
                        response.data.user_exam_id +
                        "/" +
                        response.data.user_id +
                        "/" +
                        response.data.exam_id
                      );
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>