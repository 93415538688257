<template>
  <div class="row">
    <div class="col-md-12">
      <div
        style="
          background-color: #b00202;
          border-radius: 5px;
          padding: 15px 15px 15px 15px;
          border-left: 8px solid #7a0101;
          color: whitesmoke;
        "
      >
        <h3 style="border-bottom: 2px solid #7a0101; margin-bottom: 10px">
          Catatan
        </h3>

        Halaman ini digunakan untuk melakukan registrasi bimbingan
        belajar/tutoring yang disediakan oleh Sejawat LC. Tutoring Sejawat
        diadakan berdasarkan periode. Periode yang dibuka akan muncul pada
        bagian di bawah ini: <br />
        <br />
        NOTE: Jika tidak ada apapun pada daftar dibawah ini, berarti tidak ada
        periode yang dibuka oleh Sejawat LC.
      </div>
    </div>
    <div
      class="col-md-12 mt-3"
      v-for="item in tutoringPeriodList"
      v-bind:key="item.id"
      style="color: #b596ad"
    >
      <md-card
        class="md-danger sm"
        md-with-hover
        style="
          border-radius: 3px;
          -moz-user-select: none;
          -khtml-user-select: none;
          -webkit-user-select: none;
          -ms-user-select: none;
          user-select: none;
        "
      >
        <!-- <md-ripple> -->
          <md-card-header>
            <div class="md-title">Open Period</div>
            <div class="md-subhead">
              {{ formatDate(item.start_date) }} until
              {{ formatDate(item.end_date) }}
            </div>
          </md-card-header>

          <md-card-content>
            Hai para sejawat. Kabar baik nih periode baru tutor Sejawat LC sudah
            dibuka untuk pendaftaran. Yuk daftar segera!
          </md-card-content>

          <md-card-actions>
            <md-button @click="register(item.id)">
              Yes! Count me in!
            </md-button>
          </md-card-actions>
        <!-- </md-ripple> -->
      </md-card>
    </div>

    <div
      class="col-md-12 mt-3 text-center"
      v-if="tutoringPeriodList.length <= 0"
    >
      <base-alert type="success">
        <span>Sorry! There is no open period for tutoring right now.</span>
      </base-alert>
    </div>
  </div>
</template>

<script>
import { BaseAlert } from "@/components";
export default {
  name: "tutoring_registration",
  components: {
    BaseAlert,
  },
  data() {
    return {
      tutoringPeriodList: [],
    };
  },
  created() {
    this.apiGet("getActivePeriod")
      .then((response) => {
        this.tutoringPeriodList = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {
    register(id) {
      this.$router.push("/tutoring-registration/" + id);
    },
    formatDate(d) {
      var date = new Date(d);

      if (isNaN(date.getTime())) {
        return d;
      } else {
        var month = new Array();
        month[0] = "Jan";
        month[1] = "Feb";
        month[2] = "Mar";
        month[3] = "Apr";
        month[4] = "May";
        month[5] = "Jun";
        month[6] = "Jul";
        month[7] = "Aug";
        month[8] = "Sept";
        month[9] = "Oct";
        month[10] = "Nov";
        month[11] = "Dec";

        var day = date.getDate();

        if (day < 10) {
          day = "0" + day;
        }

        return day + "-" + month[date.getMonth()] + "-" + date.getFullYear();
      }
    },
  },
};
</script>

<style>
</style>