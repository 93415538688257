<template>
  <div>
    <div class="tryout-paper-container">
      <div class="row">
        <div class="exam-timer-container">
          <i class="tim-icons icon-watch-time"></i>
          (Remaining Time)
          {{ remaining_time }}
        </div>
        <div class="col-lg-3">
          <div
            class="mb-3"
            style="
              background-color: #b00202;
              border-radius: 5px;
              padding: 15px 15px 15px 15px;
              border-left: 8px solid #7a0101;
              max-height: 80vh;
              overflow-y: auto;
              color: whitesmoke;
              text-align: justify;
            "
          >
            <h3 style="border-bottom: 2px solid #7a0101; margin-bottom: 10px">
              Catatan
            </h3>

            <ul style="margin-left: -22px; font-size: 14px;">
              <li>
                Peserta ujian hanya dapat melakukan ujian ini 1 KALI SAJA.
              </li>
              <li>
                Pastikan koneksi lancar dan tidak ada potensi terganggu. Karena
                gangguan koneksi mengakibatkan ujian ini tidak dapat di-submit.
              </li>
              <li>
                Jika tidak dapat terelakkan saat peserta ujian mengalami kendala
                mengerjakan ujian padahal ticket untuk ujian ini sudah terpakai,
                peserta ujian dapat meminta reset ticket ujian kepada admin
                Sejawat LC.
              </li>
              <li>
                Terdapat timer pengerjaan soal sesuai dengan durasi yang telah
                ditentukan oleh tim Sejawat LC untuk ujian ini. Saat timer habis
                pengerjaan anda akan otomatis ter-submit.
              </li>
              <li>
                PENTING!!! JANGAN MELAKUKAN REFRESH, BACK, FORWARD, atau apapun ACTION BROWSER pada HALAMAN INI SAAT SEDANG
                MENGERJAKAN UJIAN TRYOUT. Saat halaman ter-refresh, anda akan
                keluar dari halaman pengerjaan ujian dan tidak bisa kembali
                karena ticket ujian sudah terpakai.
              </li>
            </ul>
          </div>
        </div>

        <!-- <div class="col-lg-6">
          <div class="tryout-paper-sheet">
            <ol type="1" style="margin-left: -15px; margin-bottom: 5px">
              <li
                class="exam-question-item mt-3"
                v-for="question in exam_questions"
                :key="question.id"
              >
                <div class="row">
                  <div class="col-md-12" style="text-align: justify">
                    <b>{{ question.question_category }}: </b>
                    {{ question.question_text }}
                    <br />
                    <div class="row">
                      <div class="col-md-9">
                        <base-radio v-model="question.user_answer" name="A">
                          {{ question.option1 }}
                        </base-radio>
                        <base-radio v-model="question.user_answer" name="B">
                          {{ question.option2 }}
                        </base-radio>
                        <base-radio v-model="question.user_answer" name="C">
                          {{ question.option3 }}
                        </base-radio>
                        <base-radio v-model="question.user_answer" name="D">
                          {{ question.option4 }}
                        </base-radio>
                        <base-radio v-model="question.user_answer" name="E">
                          {{ question.option5 }}
                        </base-radio>
                      </div>
                      <div class="col-md-3 text-center p-2">
                        <img
                          v-bind:src="
                            backend_root + '/files/' + question.image_name
                          "
                          v-if="question.image_name !== null"
                          title="Click to enlarge"
                          style="height: 150px; cursor: pointer"
                          @click="
                            preview_image =
                              backend_root + '/files/' + question.image_name;
                            show_modal = true;
                          "
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ol>
          </div>
        </div> -->

        <div class="col-lg-6 mb-3">
          <div class="tryout-paper-sheet">
            <ol type="1" style="margin-left: -15px; margin-bottom: 5px;">
              <li
                class="exam-question-item mt-1"
                :value="active_index + 1"
              >
                <div class="row">
                  <div class="col-md-12" style="text-align: justify">
                    <b>{{ exam_questions[active_index].question_category }}: </b>
                    {{ exam_questions[active_index].question_text }}
                    <br />
                    <div class="row">
                      <div class="col-md-9">
                        <base-radio v-model="exam_questions[active_index].user_answer" name="A">
                          {{ exam_questions[active_index].option1 }}
                        </base-radio>
                        <base-radio v-model="exam_questions[active_index].user_answer" name="B">
                          {{ exam_questions[active_index].option2 }}
                        </base-radio>
                        <base-radio v-model="exam_questions[active_index].user_answer" name="C">
                          {{ exam_questions[active_index].option3 }}
                        </base-radio>
                        <base-radio v-model="exam_questions[active_index].user_answer" name="D">
                          {{ exam_questions[active_index].option4 }}
                        </base-radio>
                        <base-radio v-model="exam_questions[active_index].user_answer" name="E">
                          {{ exam_questions[active_index].option5 }}
                        </base-radio>
                      </div>
                      <div class="col-md-3 text-center p-2">
                        <img
                          v-bind:src="
                            backend_root + '/files/' + exam_questions[active_index].image_name
                          "
                          title="Click to enlarge"
                          style="height: 150px; cursor: pointer"
                          v-if="exam_questions[active_index].image_name !== null"
                          @click="
                            preview_image =
                              backend_root + '/files/' + exam_questions[active_index].image_name;
                            show_modal = true;
                          "
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ol>
          </div>
          <div class="row mt-3">
            <div class="col-6 text-right">
              <md-button 
                class="md-icon-button md-raised text-center" 
                style="height: 55px; width: 55px;"
                title="Soal Sebelumnya"
                @click="() => {
                  if (active_index > 0) {
                    active_index--;
                  } else {
                    showErrorWithCustomTitleAndContent('Navigation Error', 'Pertanyaan sudah yang paling awal.')
                  }
                }"
              >
                <i class="tim-icons icon-minimal-left" style="font-size: 26px;"></i>
              </md-button>
            </div>
            <div class="col-6">
              <md-button 
                class="md-icon-button md-raised text-center" 
                style="height: 55px; width: 55px;"
                title="Soal Selanjutnya"
                @click="() => {
                  if (active_index < (exam_questions.length - 1)) {
                    active_index++;
                  } else {
                    showErrorWithCustomTitleAndContent('Navigation Error', 'Pertanyaan sudah yang paling akhir.')
                  }
                }"
              >
                <i class="tim-icons icon-minimal-right" style="font-size: 26px;"></i>
              </md-button>
            </div>

            <div class="col-12 mt-5 text-center">
              <md-button
                class="md-raised md-accent"
                title="Submit Exam"
                style="border-radius: 5px; font-size: 16px; padding: 10px 10px 10px 10px;"
                @click="btnSubmitAction"
              >
                <i class="tim-icons icon-send"></i> Submit Exam
              </md-button>
            </div>
          </div>
        </div>

        <div class="col-lg-3">
          <div
            class="text-center"
            style="
              background-color: #3c2240;
              padding: 15px 15px 15px 15px;
              border-radius: 5px;
              max-height: 80vh;
              overflow-y: auto;
            "
          >
            <md-chip 
              class="md-clear" 
              md-clickable 
              style="
                width: 17%;
                margin: 2px 2px 5px 2px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;"
              v-for="(question, index) in exam_questions"
              :key="question.id"
              @click="active_index = index"
            >
              {{index + 1}}
            </md-chip>
          </div>
        </div>
      </div>
    </div>

    <md-dialog 
      :md-active.sync="show_modal"
    >
      <md-dialog-content>
        <img v-bind:src="preview_image" alt="" />
      </md-dialog-content>

      <md-dialog-actions>
        <md-button class="md-primary" @click="show_modal = false"><i class="tim-icons icon-simple-remove"></i> Close</md-button>
      </md-dialog-actions>
    </md-dialog>

  </div>
</template>

<script>
import BaseRadio from "../../components/BaseRadio.vue";
export default {
  name: "tryout-paper",
  components: { BaseRadio },
  data() {
    return {
      exam_properties: null,
      exam_questions: [],
      exam_duration: 0,
      start_time: null,
      remaining_time: "00:00:00",
      active_index: 0,
      show_modal: false,
      preview_image: null,
    };
  },
  created() {
    // grab route params and store it into data storage
    this.exam_properties = this.$route.params;

    this.fetchUserExamQuestionsList();

    window.onbeforeunload = function() {
      return false;
    }
  },
  methods: {
    fetchUserExamQuestionsList() {
      this.apiGet(
        "userExamPermission/" +
          this.exam_properties.user_exam_id +
          "/" +
          this.exam_properties.user_id +
          "/" +
          this.exam_properties.exam_id
      )
        .then((response) => {
          if (!response.data.permitted) {
            // user is not allowed to take this exam
            // redirect to tryout selection list page
            this.$router.push("/open-tryouts");
          } else {
            // user is allowed to take the exam
            // set exam taken_at value
            this.apiPut(
              "userExamTaking/" + this.exam_properties.user_exam_id
            ).then((response) => {
              if (response.status == 200) {
                // fetch exam question details
                this.apiGet(
                  "fetchUserExamQuestions/" + this.exam_properties.user_exam_id
                ).then((response) => {
                  // set response data containing exam questions list into data store
                  this.exam_questions = response.data;

                  // get exam duration from user exam data
                  this.apiGet("getExamPeriod/" + this.exam_properties.exam_id)
                    .then((response) => {
                      this.exam_duration = response.data.duration;

                      // convert duration from minutes to seconds
                      this.exam_duration = this.exam_duration * 60;

                      // start timer countdown
                      this.startWorkingTimerCountdown();
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                });
              }
            });
          }
        })
        .catch((error) => {
          if (error.response.status == 422) {
            // status code captured with code 422
            // redirect to tryout selection list page
            this.$router.push("/open-tryouts");
          }
        });
    },
    startWorkingTimerCountdown() {
      var here = this;

      // get starting time
      var start = new Date().getTime();
      here.start_time = start;

      var interval = 1000; // ms
      var expected = Date.now() + interval;
      setTimeout(step, interval);
      function step() {
        var dt = Date.now() - expected; // the drift (positive for overshooting)

        if (dt > interval) {
          // something really bad happened. Maybe the browser (tab) was inactive?
          // possibly special handling to avoid futile "catch up" run
        }

        // do what is to be done
        here.exam_duration--;

        var d = JSON.parse(JSON.stringify(here.exam_duration));

        var h = Math.floor(d / 3600);
        var m = Math.floor((d % 3600) / 60);
        var s = Math.floor((d % 3600) % 60);

        var diff = new Date().getTime() - start;

        here.remaining_time =
          here.timePad(h) + ":" + here.timePad(m) + ":" + here.timePad(s);

        // check if countdown is done or not
        if (here.exam_duration == 0) {
          // time is out, auto submit this user exam data
          here.submitExam();

          // break the process
          return;
        }

        expected += interval;
        setTimeout(step, Math.max(0, interval - dt)); // take into account drift
      }
    },
    btnSubmitAction() {
      // submitting exam needs user confirmation avoid accidental submit
      // show confirmation message
      this.$swal({
        title: "Are You Sure?",
        text:
          "Convince yourself before submitting the exam. Check your answers first.",
        icon: "question",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          // user confirmed the action, submit the exam
          this.submitExam();
        }
      });
    },
    submitExam() {
      // proceed to exam submission
      this.apiPost("submitExam", {
        exam_properties: this.exam_properties,
        exam_questions: this.exam_questions,
      })
        .then((response) => {
          if (response.status == 200) {
            // exam submission successful
            // show API success message
            this.$swal({
              title: "Your Exam Score:" + response.data.exam_score,
              text: response.data.message,
              icon: "success",
            });

            // redirect user to exam history
            this.$router.push("/tryouts-history");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  // background: #f1f1f1;
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: #fa7000;
  border-radius: 50px;
  // border: 6px solid transparent;
  background-clip: content-box;
}
::-webkit-scrollbar-thumb:hover {
  background: #fab300;
}
.exam-timer-container {
  width: 35%;
  top: 0%;
  left: 50%;
  margin-left: -17.5%;
  position: fixed;
  z-index: 100;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  font: bold 1.5em/1.5em "Helvetica Neue", Arial, Helvetica, Geneva, sans-serif;
  text-align: center;
  color: hsla(0, 0%, 20%, 1);
  text-shadow: hsla(0, 0%, 40%, 0.5) 0 -1px 0, hsla(0, 0%, 100%, 0.6) 0 2px 1px;

  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.8);
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.8);
  -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.8);

  background-color: hsl(0, 0%, 90%);

  background-image: linear-gradient(
    180deg,
    hsl(0, 0%, 78%) 0%,
    hsl(0, 0%, 90%) 47%,
    hsl(0, 0%, 78%) 53%,
    hsl(0, 0%, 70%) 100%
  );
}
.tryout-paper-container {
  background-color: transparent;
  width: 95%;
  margin: 0px auto;
  padding-top: 55px;
}
.tryout-paper-sheet {
  background-color: whitesmoke;
  // margin-top: 25px;
  margin-top: 0px;
  padding: 15px 25px 15px 15px;
  border-radius: 5px;
}
.exam-question-item {
  color: black;
}
.answer-radio-option {
  border-color: 1px solid black;
}
.btn-submit {
  position: fixed;
  right: 15px;
  bottom: 10px;
  font-size: 18pt;
}

@media screen and (max-width: 600px) {
  .exam-timer-container {
    width: 100%;
    top: 0%;
    left: 0%;
    margin-left: 0%;
    position: fixed;
    padding-top: 10px;
    z-index: 100;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;

    font: bold 1.5em/1.5em "Helvetica Neue", Arial, Helvetica, Geneva, sans-serif;
    text-align: center;
    color: hsla(0, 0%, 20%, 1);
    text-shadow: hsla(0, 0%, 40%, 0.5) 0 -1px 0, hsla(0, 0%, 100%, 0.6) 0 2px 1px;

    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.8);
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.8);
    -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.8);

    background-color: hsl(0, 0%, 90%);

    background-image: linear-gradient(
      180deg,
      hsl(0, 0%, 78%) 0%,
      hsl(0, 0%, 90%) 47%,
      hsl(0, 0%, 78%) 53%,
      hsl(0, 0%, 70%) 100%
    );
  }
}
</style>