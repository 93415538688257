<template>
  <div class="row">
    <div class="col-md-12">
      <div
        style="
          background-color: #b00202;
          border-radius: 5px;
          padding: 15px 15px 15px 15px;
          border-left: 8px solid #7a0101;
          color: whitesmoke;
        "
      >
        <h3 style="border-bottom: 2px solid #7a0101; margin-bottom: 10px">
          FORM UKMP2DG PESERTA SEJAWAT<br />
          <small>
            Periode:
            {{ formatDate(period_data.start_date) }}
            s/d
            {{ formatDate(period_data.end_date) }}
          </small>
        </h3>

        <ul style="margin-left: -22px; margin-bottom: 5px; font-style: italic">
          <li>
            Pengisian form pendaftaran maksimal H-1 sebelum kelas tersebut
          </li>
          <li>
            Setelah mengisi form dan menyelesaikan administrasi pembayaran, nama
            kalian akan masuk kedalam ABSENSI (akan di share di group LINE)
          </li>
        </ul>

        <br />
        Contact Admin:<br />
        CHIQUITAFIDELIA (LINE)<br />
        GITTAIRSANIA88 (LINE)<br />
        <br />

        Nama dan foto yang terkait dengan Akun Google Anda akan direkam saat
        Anda mengupload file dan mengirim formulir ini.
      </div>
    </div>
    <div class="col-md-12 mt-3" style="color: #b596ad">
      <card>
        <div class="row">
          <div class="col-md-12 mb-3">
            <small>
              <i>
                **Note: data biodata seperti nama lengkap, line ID, asal
                universitas, dan angkatan akan otomatis terisi jika anda sudah
                melengkapi identitas diri pada halaman Edit Profile. Sehingga
                untuk kedepannya setiap kali anda melakukan registrasi tidak
                perlu melengkapi data diri.
              </i>
            </small>
          </div>
          <div class="col-md-6">
            <md-field>
              <label>Nama Lengkap</label>
              <md-input
                v-model="form.full_name"
                placeholder="Nama Lengkap (saat ZOOM menggunakan nama lengkap juga)"
              ></md-input>
            </md-field>
            <md-field>
              <label>Line ID</label>
              <md-input
                v-model="form.line_id"
                placeholder="ID Line anda yang aktif"
              ></md-input>
            </md-field>
          </div>
          <div class="col-md-6">
            <md-field>
              <label>Asal Universitas</label>
              <md-input
                v-model="form.origin_university"
                placeholder="Universitas anda saat ini"
              ></md-input>
            </md-field>
            <md-field>
              <label>Angkatan</label>
              <md-input
                v-model="form.student_generation"
                placeholder="Tahun angkatan studi anda"
                type="number"
              ></md-input>
            </md-field>
          </div>
          <div class="col-md-12">
            <br />
            <label style="font-size: 11pt">Sistem Bimbel: </label>
            <br />
            <div v-for="pricingSystem in pricing_system" v-bind:key="pricingSystem">
              <md-radio
                v-model="form.tutoring_system"
                :value="pricingSystem"
              >
                {{ pricingSystem }}
              </md-radio>
            </div>

            <br />
            <label style="font-size: 11pt">
              Materi Bimbel/Sesi Dan Bundle:
            </label>
            <br />

            <div v-for="subjectBundling in subject_bundling" v-bind:key="subjectBundling">
              <md-checkbox v-model="form.subject_and_bundle" :value="subjectBundling">
                {{ subjectBundling }}
              </md-checkbox>
            </div>
          </div>
        </div>
      </card>
      <card class="col-md-8" style="margin: -10px auto">
        <div class="row">
          <div class="col-md-12">
            <label style="font-size: 11pt">Kode Transfer: </label>
            <br />
            <img
              v-bind:src="backend_root + '/files/' + period_data.file_name"
            />
          </div>
        </div>
      </card>
      <br />
      <card class="col-md-8" style="margin: 10px auto">
        <div class="row">
          <div class="col-md-12">
            <label style="font-size: 11pt"
              >Bukti Transfer (PASTIKAN, di BERITA/KETERANGAN TF, sudah
              disertakan KODE TRANSFER):
            </label>
            <br />
            <img src="img/1.jpg" />
          </div>
          <div class="col-md-12 mt-3">
            <upload-field-single v-model="form.verification_image" />
          </div>
        </div>
      </card>
      <br />
      <card class="col-md-8" style="margin: -10px auto">
        <div class="row">
          <div class="col-md-12">
            <label style="font-size: 11pt"
              >Setelah SUBMIT form, Ayo Join group LINE UKMP2DG untuk dapatkan
              link zoom:
            </label>
            <br />
            <img src="img/CLICK_HERE-removebg-preview.png" />
          </div>
        </div>
      </card>
      <br />
      <div class="col-md-8" style="margin: 0px auto">
        <md-button
          class="md-raised md-accent"
          style="float: right; border-radius: 3px; margin-right: -15px"
          @click="submit"
        >
          <i class="tim-icons icon-send pr-2"></i>
          Submit Registration
        </md-button>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "../components/Cards/Card.vue";
import UploadFieldSingle from "../components/UploadFieldSingle.vue";
export default {
  name: "tutoring-registration-form",
  components: { Card, UploadFieldSingle },
  data() {
    return {
      form: {
        full_name: null,
        line_id: null,
        origin_university: null,
        student_generation: null,
        tutoring_system: null,
        subject_and_bundle: [],
        verification_image: [],
      },
      period_id: null,
      period_data: {},
      pricing_system: [],
      subject_bundling: []
    };
  },
  created() {
    // set id param from URL
    this.period_id = this.$route.params.id;

    // get period data based from period ID
    this.apiGet("tutoringPeriod/" + this.period_id)
      .then((response) => {
        // set period data to form data store
        this.period_data = response.data;

        // predefined field values from vuex store
        this.form.full_name = this.$store.state.userData.userName;
        this.form.line_id = this.$store.state.userProfile.lineId;
        this.form.origin_university = this.$store.state.userProfile.originUniversity;
        this.form.student_generation = this.$store.state.userProfile.studentGeneration;

        // get pricing system list
        this.loadPricingSystem();
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {
    loadPricingSystem() {
      this.apiGet("pricingSystemList")
        .then((response) => {
          this.pricing_system = JSON.parse(response.data.array_string.replace(/\\/g, ''));
          // get subject bundling list
          this.loadSubjectBundling();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    loadSubjectBundling() {
      this.apiGet("subjectBundlingList")
        .then((response) => {
          this.subject_bundling = JSON.parse(response.data.array_string.replace(/\\/g, ''));
        })
        .catch((error) => {
          console.log(error);
        });
    },
    validateForm(formName) {
      if (formName == "verification_image") {
        // image form validation
        if (this.form.verification_image.length <= 0) {
          // no image given
          return false;
        } else {
          // user image input exists
          return true;
        }
      } else if (formName == "subject_and_bundle") {
        // multicheck field validation
        if (this.form.subject_and_bundle.length <= 0) {
          // no option selected
          return false;
        } else {
          return true;
        }
      } else {
        // standart single value form validation
        if (
          this.form[formName] === null ||
          this.form[formName] == "" ||
          this.form[formName] === undefined
        ) {
          // not valid
          return false;
        } else {
          // valid
          return true;
        }
      }
    },
    submit() {
      // validate form values
      let isValid = true;

      isValid = this.validateForm("full_name")
        ? true
        : this.showErrorWithCustomName("Nama Lengkap");
      isValid = this.validateForm("line_id")
        ? true
        : this.showErrorWithCustomName("Line ID");
      isValid = this.validateForm("origin_university")
        ? true
        : this.showErrorWithCustomName("Asal Universitas");
      isValid = this.validateForm("student_generation")
        ? true
        : this.showErrorWithCustomName("Angkatan");
      isValid = this.validateForm("tutoring_system")
        ? true
        : this.showErrorWithCustomName("Sistem Bimbel");
      isValid = this.validateForm("subject_and_bundle")
        ? true
        : this.showErrorWithCustomName("Materi Bimbel/Sesi Dan Bundle");
      isValid = this.validateForm("verification_image")
        ? true
        : this.showErrorWithCustomName("Bukti Transfer");

      if (isValid) {
        // form utilizing image, use multipart header content-type
        // multipart type require formdata to be passed as value parameters
        var formData = new FormData();

        // append required data into formdata body
        formData.append("full_name", this.form.full_name);
        formData.append("line_id", this.form.line_id);
        formData.append("origin_university", this.form.origin_university);
        formData.append("student_generation", this.form.student_generation);
        formData.append("tutoring_system", this.form.tutoring_system);
        formData.append(
          "subject_and_bundle",
          JSON.stringify(this.form.subject_and_bundle)
        );
        formData.append(
          "verification_image",
          this.form.verification_image[0].file
        );
        formData.append("user_id", this.$store.state.userData.userId);
        formData.append("period_id", this.$route.params.id);

        this.apiPostMultipart("periodRegistration", formData)
          .then((response) => {
            console.log(response);
            // period registration successful
            // redirect to period registration page
            this.showSuccessMessage(response.data.message);
            this.$router.push("/tutoring-registration");
          })
          .catch((error) => {
            this.showApiErrorMessage("Period registration failed");
          });
      }
    },
  },
};
</script>

<style scoped>
.md-radio {
  display: flex;
}
.md-checkbox {
  display: flex;
}
</style>