<template>
  <div>
    <VueFileAgent
      ref="vueFileAgent"
      v-model="fileRecords"
      :theme="'list'"
      :multiple="false"
      :deletable="true"
      :meta="true"
      :accept="'image/*,.zip'"
      :maxSize="'2MB'"
      :helpText="helperText"
      :errorText="{
        type: 'Invalid file type. Only images and zip files Allowed',
        size: 'Files should not exceed 2MB in size',
      }"
      @select="filesSelected($event)"
      @beforedelete="onBeforeDelete($event)"
      @delete="fileDeleted($event)"
    />
  </div>
</template>

<script>
export default {
  name: 'upload-field-single', 
  data() {
    return{
      helperText: "Choose and upload your file here.",
      fileRecords: [],
      uploadUrl: null,
      uploadHeaders: { "X-Test-Header": "vue-file-agent" },
      fileRecordsForUpload: [],
    }
  }, 
  created() {}, 
  methods: {
    uploadFiles: function () {
      // Using the default uploader. You may use another uploader instead.
      this.$refs.vueFileAgent.upload(
        this.uploadUrl,
        this.uploadHeaders,
        this.fileRecordsForUpload
      );
      this.fileRecordsForUpload = [];
    },
    deleteUploadedFile: function (fileRecord) {
      // Using the default uploader. You may use another uploader instead.
      this.$refs.vueFileAgent.deleteUpload(
        this.uploadUrl,
        this.uploadHeaders,
        fileRecord
      );
    },
    selectFile(files) {
      this.form.transfer_code_attachment = fileToListArray;
    },
    filesSelected: function (fileRecordsNewlySelected) {
      var validFileRecords = fileRecordsNewlySelected.filter(
        (fileRecord) => !fileRecord.error
      );
      this.fileRecordsForUpload = this.fileRecordsForUpload.concat(
        validFileRecords
      );

      // emit data filerecordsforupload for parent usage of v-model
      this.$emit('input', this.fileRecordsForUpload);
    },
    onBeforeDelete: function (fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        // queued file, not yet uploaded. Just remove from the arrays
        this.fileRecordsForUpload.splice(i, 1);
        var k = this.fileRecords.indexOf(fileRecord);
        if (k !== -1) this.fileRecords.splice(k, 1);
      } else {
        if (confirm("Are you sure you want to delete?")) {
          this.$refs.vueFileAgent.deleteFileRecord(fileRecord); // will trigger 'delete' event
        }
      }
    },
    fileDeleted: function (fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1);
      } else {
        this.deleteUploadedFile(fileRecord);
      }
    },
  }
}
</script>

<style>

</style>