<template>
  <div class="row">
    <div v-if="user_exam_history.length <= 0" class="col-md-12 text-center">
      <base-alert type="success">
        <span>
          You haven't take any exam for now.
        </span>
      </base-alert>
    </div>

    <div class="col-md-12 mb-3" v-else v-for="user_exam in user_exam_history" :key="user_exam.user_exam_id">
      <md-list class="md-triple-line md-dense">
        <md-list-item>
          <md-avatar>
            <img src="img/BackDistantIrishterrier-size_restricted.gif" width="35%" />
          </md-avatar>

          <div class="md-list-item-text">
            <span style="font-size: 12pt">
              Tryout Periode:
              {{ formatDate(user_exam.exam_start_date) }}
              s/d
              {{ formatDate(user_exam.exam_end_date) }}
            </span>
            <span>
              Tipe: {{ user_exam.exam_type }}
            </span>
            <p>
              Anda telah mengikuti ujian yang disediakan Sejawat LC ini pada
              tanggal: {{ formatDateWithTime(user_exam.taken_at) }}.
            </p>
          </div>
        </md-list-item>
      </md-list>
      <md-divider style="background-color: grey"></md-divider>
      <md-card>
        <md-card-expand>
          <md-card-actions>
            <div style="width: 100%; margin-left: 8px; margin-right: 10px">
              <span style="left: 0">
                <b>
                  Score Ujian Anda:
                  <span style="color: #e1a500; font-size: 14pt">{{ user_exam.exam_score }}</span>
                </b>
              </span>
              <div style="float: right"><b>Lihat Hasil Koreksi Ujian Ini.</b></div>
            </div>

            <md-card-expand-trigger>
              <md-button class="md-icon-button">
                <md-icon>
                  <i class="tim-icons icon-minimal-down" style="font-size: 12pt"></i>
                </md-icon>
              </md-button>
            </md-card-expand-trigger>
          </md-card-actions>

          <md-card-expand-content style="border-top: 1px solid grey">
            <md-card-content style="max-height: 85vh; overflow-y: auto;">
              <ol type="1" style="margin-left: -10px;">
                <li v-for="exam_detail in user_exam.exam_detail" :key="exam_detail.id">
                  <div class="row mb-3">
                    <div class="col-md-12" style="text-align: justify;">
                      <b>{{ exam_detail.question_data.question_category }}: </b>
                      {{ exam_detail.question_data.question_text }}
                    </div>
                    <div class="col-md-12">
                      <ol type="A" style="margin-left: -22px;">
                        <li v-if="exam_detail.question_data.correct_answer == 'A'"
                          style="font-weight: bold; color: orange;">
                          {{ exam_detail.question_data.option1 }}
                        </li>
                        <li v-else>{{ exam_detail.question_data.option1 }}</li>
                        <li v-if="exam_detail.question_data.correct_answer == 'B'"
                          style="font-weight: bold; color: orange;">
                          {{ exam_detail.question_data.option2 }}
                        </li>
                        <li v-else>{{ exam_detail.question_data.option2 }}</li>
                        <li v-if="exam_detail.question_data.correct_answer == 'C'"
                          style="font-weight: bold; color: orange;">
                          {{ exam_detail.question_data.option3 }}
                        </li>
                        <li v-else>{{ exam_detail.question_data.option3 }}</li>
                        <li v-if="exam_detail.question_data.correct_answer == 'D'"
                          style="font-weight: bold; color: orange;">
                          {{ exam_detail.question_data.option4 }}
                        </li>
                        <li v-else>{{ exam_detail.question_data.option4 }}</li>
                        <li v-if="exam_detail.question_data.correct_answer == 'E'"
                          style="font-weight: bold; color: orange;">
                          {{ exam_detail.question_data.option5 }}
                        </li>
                        <li v-else>{{ exam_detail.question_data.option5 }}</li>
                      </ol>
                    </div>
                    <div class="col-md-12">
                      <b>
                        <i v-if="exam_detail.question_data.user_answer == exam_detail.question_data.correct_answer"
                          style="color: #00d138;">
                          Jawaban Anda:
                          {{ (exam_detail.question_data.user_answer === null) ? 'KOSONG' :
                            exam_detail.question_data.user_answer }}
                          (BENAR)
                        </i>
                        <i v-else style="color: #fa3402;">
                          Jawaban Anda:
                          {{ (exam_detail.question_data.user_answer === null) ? 'KOSONG' :
                            exam_detail.question_data.user_answer }}
                          (SALAH)
                        </i>
                      </b>
                    </div>
                    <div class="col-md-12">
                      <b>Penjelasan soal: </b>
                      {{ (exam_detail.question_data.explanation) ? exam_detail.question_data.explanation : '-' }}
                    </div>
                  </div>
                </li>
              </ol>
            </md-card-content>
          </md-card-expand-content>
        </md-card-expand>
      </md-card>
    </div>
  </div>
</template>

<script>
import BaseAlert from '../../components/BaseAlert.vue'
export default {
  name: "tryouts-history",
  components: { BaseAlert },
  data() {
    return {
      user_exam_history: [],
    }
  },
  created() {
    // load user tryouts history from API
    this.fetchUserTryoutsHistory()
  },
  methods: {
    fetchUserTryoutsHistory() {
      this.apiGet(
        "fetchTryoutsHitory/" + this.$store.state.userData.userId
      ).then((response) => {
        this.user_exam_history = response.data
      })
    },
  },
}
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  // background: #f1f1f1;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #fa7000;
  border-radius: 50px;
  // border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background: #fab300;
}
</style>