<template>
  <div class="row">
    <div class="col-md-8">
      <card style="color: #b596ad">
        <!-- both type of user can do edit password -->
        <!-- make edit password field appear to everuone without checking -->
        <div class="row">
          <div class="col-12">
            <h3>Update Profile Form</h3>

            <md-field>
              <label>New Password</label>
              <md-input v-model="form.password" type="password"></md-input>
            </md-field>

            <md-field>
              <label>Confirm New Password</label>
              <md-input
                v-model="form.confirmPassword"
                type="password"
              ></md-input>
            </md-field>
          </div>
          <div class="col-12" v-if="!$store.state.isAdmin">
            <!-- this is for non admin/student section -->
            <md-field>
              <label>Address</label>
              <md-input
                v-model="form.address"
                placeholder="New Address"
              ></md-input>
            </md-field>

            <md-field>
              <label>Phone Number</label>
              <md-input
                v-model="form.phone_number"
                placeholder="New Phone Number"
              ></md-input>
            </md-field>

            <label style="font-size: 11pt">Choose Your Gender: </label>
            <br />
            <md-radio v-model="form.gender" value="M">Male</md-radio>
            <md-radio v-model="form.gender" value="F">Female</md-radio>
            <md-radio v-model="form.gender" value="O">Other</md-radio>

            <md-field>
              <label>University Of Origin</label>
              <md-input
                v-model="form.origin_university"
                placeholder="Current University"
              ></md-input>
            </md-field>

            <md-field>
              <label>College Generation</label>
              <md-input
                v-model="form.student_generation"
                placeholder="Year Of College Generation"
                type="number"
              ></md-input>
            </md-field>

            <md-field>
              <label>Line ID</label>
              <md-input
                v-model="form.line_id"
                placeholder="Your Active Line ID"
              ></md-input>
            </md-field>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-right">
            <md-button class="md-raised md-accent" style="margin-right: 0px;" @click="submit">
              <i class="tim-icons icon-send"></i>&nbsp;Submit Edit Profile
            </md-button>
          </div>
        </div>
      </card>
    </div>
    <div class="col-md-4">
      <card type="user">
        <div class="author">
          <div class="block block-one"></div>
          <div class="block block-two"></div>
          <div class="block block-three"></div>
          <div class="block block-four"></div>
          <a>
            <img class="avatar" src="img/default-avatar.png" alt="..." />
            <h4 class="title">{{ $store.state.userData.userName }}</h4>
          </a>
          <p class="description" style="font-size: 10pt">
            Status User:
            {{ $store.state.isAdmin ? "Admin Sejawat LC" : "Siswa Sejawat LC" }}
            <br />
            Email Address: {{ $store.state.userData.userEmail }}
          </p>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
export default {
  name: "edit_profile",
  data() {
    return {
      form: {
        password: null,
        confirmPassword: null,
        address: null,
        phone_number: null,
        gender: null,
        origin_university: null,
        student_generation: null,
        line_id: null,
      },
    };
  },
  created() {
    this.fetchUserProfile();
  },
  methods: {
    submit() {
      // prepare data object collection for input values
      let dataObj = {};

      // both admin and user can change password
      // do validation for password field
      // first check if password field is null or not
      if (this.form.password !== null || this.form.password != "") {
        // user attempt to change password
        // check if the new password match with the confirmation
        if (this.form.password === this.form.confirmPassword) {
          // given password and confirmation match
          // add to data object
          dataObj["password"] =
            this.form.password == "" ? null : this.form.password;
        } else {
          // given password and confirmation do not match
          // show validation error message
          this.$notify({
            title: "Invalid Input",
            message: "Password and confirmation do not match.",
            icon: "tim-icons icon-simple-remove",
            horizontalAlign: "right",
            verticalAlign: "bottom",
            type: "info",
            timeout: 3000,
          });

          // error detected, invalidate process
          return;
        }
      } else {
        // password is null
        // continue with password as null
        dataObj["password"] = null;
      }

      // check if user is admin or not
      // admin and non admin have different form inputs
      if (!this.$store.state.isAdmin) {
        // user is non admin
        // include additional values to data object
        dataObj["address"] = this.form.address;
        dataObj["phone_number"] = this.form.phone_number;
        dataObj["gender"] = this.form.gender;
        dataObj["origin_university"] = this.form.origin_university;
        dataObj["student_generation"] = this.form.student_generation;
        dataObj["line_id"] = this.form.line_id;
        dataObj["isAdmin"] = false;
        dataObj["user_id"] = this.$store.state.userData.userId;
        dataObj["profile_id"] = this.$store.state.userProfile.profileId;
      } else {
        // user is admin, set isAdmin flag to true
        dataObj["isAdmin"] = true;
        dataObj["user_id"] = this.$store.state.userData.userId;
      }

      // buat anchor point variable
      let here = this;

      // continue process with confirmation first.
      this.$swal({
        title: "Are You Sure?",
        text: "Profile will be updated with new information.",
        icon: "question", 
        showCancelButton: true,
      }).then((result) => {
        // user confirmed update profile action
        if (result.value) {
          let userProfileId = this.$store.state.userProfile.profileId;
          let apiUrl = "userProfile/" + userProfileId;

          // continue with API submit with put request
          this.apiPut(apiUrl, dataObj)
            .then((response) => {
              if (response.status == 200) {
                this.$swal({
                  title: "Success",
                  text: response.data.message,
                  icon: "success",
                });

                // reset form
                this.resetForm();

                // re-fetch user profile
                this.fetchUserProfile();
              }
            })
            .catch((error) => {
              this.$swal({
                title: "Error",
                text: response.data.message,
                icon: "error",
              });
            });
        }
      });
    },
    fetchUserProfile() {
      this.apiGet("userProfile/" + this.$store.state.userData.userId)
        .then((response) => {
          this.form.address = response.data.address;
          this.form.phone_number = response.data.phone_number;
          this.form.gender = response.data.gender;
          this.form.origin_university = response.data.origin_university;
          this.form.student_generation = response.data.student_generation;
          this.form.line_id = response.data.line_id;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    resetForm() {
      (this.form.password = null),
      (this.form.confirmPassword = null),
      (this.form.address = null),
      (this.form.phone_number = null),
      (this.form.gender = null),
      (this.form.origin_university = null),
      (this.form.student_generation = null),
      (this.form.line_id = null);
    },
  },
};
</script>

<style>
</style>